import React, { createContext, useContext, useState } from 'react';
import { dataTypes } from '../config/dataTypes';

export const ComponentVisibilityContext = createContext(null);

export function ComponentVisibilityProvider({ children }) {
    const initialState = {
        summary: false,
        snapshot: true,
        leadgen: true,
        ...Object.fromEntries(dataTypes.map(type => [type.key, true]))
    };

    const [visibleComponents, setVisibleComponents] = useState(initialState);

    const toggleComponent = (key) => {
        setVisibleComponents(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    return (
        <ComponentVisibilityContext.Provider value={{ visibleComponents, toggleComponent }}>
            {children}
        </ComponentVisibilityContext.Provider>
    );
}

export function useComponentVisibility() {
    const context = useContext(ComponentVisibilityContext);
    if (!context) {
        throw new Error('useComponentVisibility must be used within a ComponentVisibilityProvider');
    }
    return context;
}
